import React, { useEffect, useState } from 'react'
import ImageCarousel from '../../UI/container/newsrow/image-carousel/ImageCarousel'
import ChangePassword from './ChangePassword'
import axios from 'axios'
import "../../UI/container/container.module.css"
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import "../../pagination.scss"
import styles from "./profile.module.css"

function Profile(props) {
  const [history, setPosts] = useState([])

  useEffect(() => {
    const fetchAllPosts = async () => {
      try {
        const res = await axios.get("/api/auth/donation-history")
        setPosts(res?.data);
      } catch (error) {
        console.log(error)
      }
    }

    fetchAllPosts()
  }, [history.length])

  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1)
  const recordsPerPage = 20
  const numberOfPages = Math.ceil(history.length / recordsPerPage)
  const reversedPosts = [...history].reverse()
  const lastIndex = currentPage * recordsPerPage
  const firstIndex = lastIndex - recordsPerPage
  const paginatedPosts = reversedPosts.slice(firstIndex, lastIndex)
  const numbers = [...Array(numberOfPages + 1).keys()].slice(1)

  const maxElements = 10;
  /*const numbers = [...Array(numberOfPages + 1).keys()]
    .slice(1)
    .slice(currentPage - 1, Math.min(currentPage - 1 + maxElements, numberOfPages));*/


  const prevPage = () => { if (currentPage !== 1) setCurrentPage(currentPage - 1) }
  const changeCurrentPage = (id) => setCurrentPage(id)
  const nextPage = () => { if (currentPage !== numberOfPages) setCurrentPage(currentPage + 1) }


  return (
    <div className={styles.content_column}>
      <ImageCarousel />
      
      <h2 className={styles.title}>Change Account Password</h2>
      
      <ChangePassword />

      <h2 className={styles.title}>Account Donation History</h2>

      <div className={styles.box}>
      <p className={styles.overlock_Font2}>Please be aware that these logs also show the Lucent Points you've won during Discord events and contests.
      If you wish to knows what's the total amount of donations only, please send an account support ticket on our discord server so that we can provide it.</p>
</div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>Date</th>
            <th className={styles.th}>Lucent Points</th>
            <th className={styles.th}>Before</th>
            <th className={styles.th}>After</th>
          </tr>
        </thead>

        <tbody>
        {paginatedPosts.map((object) => (
          <tr>
            <td className={styles.td}>{new Date(object.timestampUtc).toLocaleDateString("en-GB")}</td>
            <td className={styles.td}>{object.cashPoint_Delta}</td>
            <td className={styles.td}>{object.cashPoint_before ?? 0}</td>
            <td className={styles.td}>{object.cashPoint_after}</td>
          </tr>
        ))}
        </tbody>
      </table>

      <Pagination>

        <PaginationItem
          className={'paginationItemStyle'}>
          <PaginationLink onClick={prevPage}>Previous</PaginationLink>
        </PaginationItem>

        {numbers.map((i, key) => (
          <PaginationItem
            key={key}
            className={'paginationItemStyle'}
            active={currentPage === i}>
            <PaginationLink onClick={() => changeCurrentPage(i)}>
              {i}
            </PaginationLink>
          </PaginationItem>
        ))}

        <PaginationItem
          className={'paginationItemStyle'}>
          <PaginationLink onClick={nextPage}>Next</PaginationLink>
        </PaginationItem>

      </Pagination>

    </div>
  )
}

export default Profile