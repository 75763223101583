import { Button } from "react-bootstrap";
import useInput from "../../../hooks/UseInput";
import "./login.css"
import { useContext } from "react";
import { AuthContext } from "../../../context/authContext";

const isNotEmpty = (value) => value.trim() !== '';

function Login(props) {

    const { login } = useContext(AuthContext)

    const {
        value: usernameValue,
        isValid: usernameIsValid,
        hasError: usernameHasError,
        valueChangeHandler: usernameChangeHandler,
        inputBlurHandler: usernameBlurHandler,
        reset: resetUsername
    } = useInput(isNotEmpty)

    const {
        value: passwordValue,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        reset: resetPassword
    } = useInput(isNotEmpty)

    let formIsValid = false

    if (usernameIsValid && passwordIsValid) {
        formIsValid = true
    }

    const submitHandler = async event => {
        event.preventDefault()

        const loginInput = {
            username: usernameValue,
            password: passwordValue
        }

        try {
            await login(loginInput)
        } catch (error) {
            console.log(error.response.data)
        }

        if (!formIsValid) return

        resetUsername()
        resetPassword()
    }

    const usernameClasses = usernameHasError ? 'form-control invalid' : 'form-control'
    const passwordClasses = passwordHasError ? 'form-control invalid' : 'form-control'

    return (
        <>
            <form onSubmit={submitHandler} >
                <div className="control-group">

                <h5 className="login_title">Login</h5>

                    <div className={usernameClasses}>
                        <input
                            type="text"
                            name="username"
                            value={usernameValue}
                            placeholder='Login ID'
                            onChange={usernameChangeHandler}
                            onBlur={usernameBlurHandler}
                        />
                        {usernameHasError && <p className="error-text">Please enter your Login ID</p>}
                    </div>
                    <div className={passwordClasses}>
                        <input
                            type="password"
                            name="password"
                            value={passwordValue}
                            placeholder='Password'
                            onChange={passwordChangeHandler}
                            onBlur={passwordBlurHandler}
                        />
                        {passwordHasError && <p className="error-text">Please enter your Password</p>}
                    </div>
                    <div>
                        <Button disabled={!formIsValid} type='submit' variant='secondary' size='sm' className="login_button">Sign In</Button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Login