import React from 'react'
import styles from "./welcome.module.css"

function Welcome() {
    return (
        <div id={styles.welcome_box}>
            <div id={styles.welcome_title}>
                <h2>Welcome to Lucent Heart Rebirth</h2>
            </div>
            <div id={styles.welcome_text}>
                <p>Lucent Heart Rebirth is a Private Server hosted by a dedicated group of people that want to create an unique and unforgettable Lucent Heart experience.
                    <br />Join us now and become a part of this nostalgic journey!</p>
            </div>
        </div>
    )
}

export default Welcome