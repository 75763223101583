import React, { useContext, useState } from 'react'
import styles from "./newsinput.module.css"
import AddIcon from '@mui/icons-material/Add';
import { AuthContext } from '../../../../context/authContext';
import axios from 'axios';

function NewsInput(props) {

    const { currentUser } = useContext(AuthContext)

    const [showContent, setShowContent] = useState(false)
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [newsInput, setNewsInput] = useState({
        title: "",
        bodyText: "",
        imageUrl: "",
        date: ""
    })

    const createPost = async (event) => {
        event.preventDefault()

        if (!newsInput.title || !newsInput.bodyText) {
            setErrorMessage("Please fill in all the fields");
            return;
        }

        const newPost = {
            title: newsInput.title,
            description: newsInput.bodyText,
            img: newsInput.imageUrl,
            date: newsInput.date
        }

        try {
            const res = await axios.post("/api/create-news", newPost)
            newPost.id = res.data
            props.onSaveNewEntry(newPost)
            setNewsInput("")
            setShowContent(!showContent)
        } catch (error) {
            setErrorMessage(error)
            console.log("error message from news input ", errorMessage)
        }
    }

    const toggleContent = () => setShowContent(!showContent)

    const newsInputHandler = event => {
        setNewsInput(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    return (
        <>
        {currentUser?.role === "admin" && 
            <div id={styles.input_box}>
                <div id={styles.add_div}>
                    <button id={styles.add_button} onClick={toggleContent}>
                        <AddIcon style={{ color: "white" }} />
                    </button>
                </div>
                {showContent &&
                    <div id={styles.input_box}>
                        <form id={styles.input_form} onSubmit={createPost}>
                            <label className={styles.input_label}>
                                <input type='text' name='title' placeholder='Title' onChange={newsInputHandler} />
                            </label>
                            <label className={styles.input_label}>
                                <input type='text' name='bodyText' id={styles.description_input} placeholder="Description of the Post" onChange={newsInputHandler} />
                            </label>
                            <label className={styles.input_label}>
                                <input type='url' name='imageUrl' placeholder='Image URL' onChange={newsInputHandler} />
                            </label>
                            <label className={styles.input_label}>
                                <input type='date' name="date" onChange={newsInputHandler}/>
                            </label>
                            <button type='submit' >Submit</button>
                        </form>
                    </div>
                }
            </div>
        }
        </>
    )
}

export default NewsInput