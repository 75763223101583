import React from 'react'
import styles from "../sidebarBox.module.css"

function Rates() {
    return (
        <div className={styles.sidebar_box}>
            <div>
                <h3 className={styles.sidebar_box_title}>SERVER RATES</h3>
            </div>
            <div className={styles.sidebar_box_info_div}>
                <h5 >EXP X1 - DROP X2</h5>
            </div>
            <div className={styles.sidebar_box_info_div}>
                <h5 >PARTY EXP BOOST</h5>
            </div>
            <div id={styles.moreInfo}>
                <p>More information regarding our server's features can be found <a href='/about'>here</a></p>
            </div>
        </div>
    )
}

export default Rates