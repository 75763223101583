import React from 'react'
import Clock from './clock/Clock'
import Rates from "./rates/Rates"
import styles from "../container.module.css"
import Events from './nextEvents/Events'
import SidebarButton from './button/SidebarButton'


function Sidebar() {
  
  return (
    <div className={`${styles.content_column} ${styles.sidebar}`}>
      <Clock/>
      <Rates/>
      <Events/>
      <SidebarButton/>
    </div>
  )
}

export default Sidebar