import React from 'react'
import ImageCarousel from '../../UI/container/newsrow/image-carousel/ImageCarousel'

import styles from "./rules.module.css"


function RulesPage() {
  return (
    <div className={styles.content_column}>
      <ImageCarousel />

      <h2 className={styles.title}>Rules of Conduct</h2>
      <div className='button_info'>

        <div className={styles.disclaimer}>
          
        <p className={styles.overlock_Font2}>
        <b>The following rules apply to all player activities and interaction within our Lucent Heart Rebirth server.
Please be aware that failure to comply with these rules of conduct may result in the suspension or termination of your Lucent Heart Rebirth game account.
Ignorance of the Lucent Heart Rebirth <a href="/rules">Rules of Conduct</a> is NOT a justification for their violation.</b>
          </p>
    
    <ol type="1">
          <li className={styles.overlock_Font}>While playing Lucent Heart Rebirth, you will respect the rights of others to play and enjoy the game. You will not insult, harass, threaten, defraud, scam, or cause distress to other players.
          You will not report players maliciously, or cause them to be investigated by the staff without reason.</li><br></br>
          <li className={styles.overlock_Font}>You will always act in good faith to other players. Not acting in good faith is defined as any behavior which is of malicious intent or disruptive to other players’ game experiences.
          This includes but is not limited to spying on, impersonating, starting drama, spreading misinformation and deceiving other players in any way, within or outside of the Lucent Heart Rebirth game client or Lucent Heart Rebirth discord server.
          This includes but is not limited to using social media, online discussion forums and/or online streaming web sites.</li><br></br>
          <li className={styles.overlock_Font}>You will not post or communicate any player's real world information (name, address, location, credit card information, driver’s license, etc.)
          through the Lucent Heart Rebirth game or on the Lucent Heart Rebirth discord server.</li><br></br>
          <li className={styles.overlock_Font}>You will not use sexually explicit, harmful, threatening, abusive, defamatory, obscene, hateful,
          or racially or ethnically offensive language in any portion of the Lucent Heart Rebirth game client or discord server.
          This includes but is not limited to the in-game chat channels.</li><br></br>
          <li className={styles.overlock_Font}>You will not post or link to any sexually explicit, harmful, threatening, abusive, defamatory, obscene, hateful,
          or racially or ethnically offensive imagery or content in any portion of the Lucent Heart Rebirth game client.</li><br></br>
          <li className={styles.overlock_Font}>You must select a login ID and character name that does not contain offensive, harmful, sexual, deceptive,
          or otherwise disruptive text, phrases, words, or use of symbols or numbers in any language, English or otherwise.
          Any of the above names which are found to contain content in violation of the above terms by the staff will be subject to immediate and non-negotiable name changes, account suspensions and/or terminations.</li><br></br>
          <li className={styles.overlock_Font}>When communicating in Lucent Heart Rebirth's General, Whisper, Zone, Guild, Horoscope and Activity chat channels you will not post spam or flood the chat channels, or otherwise abuse your chatting privileges.
          You will not use any chat channel for a purpose not intended for it (i.e. trade requests using activity chat channel).</li><br></br>
          <li className={styles.overlock_Font}>You will not organize nor be a member of any pledges, guilds or groups within Lucent Heart Rebirth’s game client or discord server that are based on or espouse any racist,
          sexist, anti-religious, anti-ethnic, anti-gay, or other beliefs or platforms which are hateful, offensive or malicious.</li><br></br>
          <li className={styles.overlock_Font}>You will not impersonate any member of the staff, including but not limited to Game Masters,
          nor will you disseminate any claim or information, whether true, false,or fabricated, on behalf of the above parties.</li><br></br>
          <li className={styles.overlock_Font}>You will follow the instructions of authorized Lucent Heart Rebirth staff (denoted by the “Game Master” role on discord and guild on the game server) while playing Lucent Heart Rebirth.
          Failure to comply with their instructions will result in account suspension or termination.</li><br></br>
          <li className={styles.overlock_Font}>You will not violate any local, state, national, or international laws or regulations while using the Lucent Heart Rebirth game client or discord server.</li><br></br>
          <li className={styles.overlock_Font}>You will not use the Lucent Heart Rebirth Ticket System to inflict harm upon, falsely accuse or harass other players,to submit false reports on any issue, or to cause undue load on the Lucent Heart Rebirth servers.
          Individuals who abuse the reporting function will be investigated and will face possible account suspension or termination.</li><br></br>
          <li className={styles.overlock_Font}>While participating in Plaver vs. Player (PvP) gameplay (including but not limited to Guild vs. Guild [GvG]), you will not participate in any form of match manipulation.
          Match manipulation is defined as any action taken to manipulate the outcome of a match, or manipulate the guild or player PvP rankings. This also includes disrupting other players’ game experience, either by interfering with normal PvP gameplay or by not actively participating in matches in good faith,
          for example, leeching, feeding, denying kills to other players, using multiple accounts to farm kills, using distracting or deceptive chat, or registering your guild to GvG and not showing up, denying the chance for other guilds of having a match.</li><br></br>
          <li className={styles.overlock_Font}>You will not operate more than one Lucent Heart Rebirth game client at a time from the same computer for use in PvP gameplay of any kind.
          You will also not use more than one client at a time to exploit in-game systems or rewards, such as farming Starry Sky Boxes, or attend in-game GM hosted events with multiple accounts for your personaly gain.
          Any use of multiple Lucent Heart Rebirth game clients from the same computer or Internet connection which is found to be malicious, disruptive,
          or in violation of any of the Lucent Heart Rebirth Rules of Conduct, will be subject to suspension or termination.</li><br></br>
          <li className={styles.overlock_Font}>In correlation with rules 13 and 14, players are prohibited from registering more than 2 characters for any Eventure System activity, this includes the use of several alts or other players' accounts. 
          Players are also prohibited from using their 2 characters simultaneously or consecutively during Eventure activities, and they must wait for other participants to finish their turn before utilizing a second character. 
          Players who are not participating in the Eventure System activities cannot interfer with or taunt those engaged in the activity, which includes providing any sort of assistance or causing disruption to other players. 
          Any violation of these rules will result in account suspension or termination.</li><br></br>
          <li className={styles.overlock_Font}>You will not use multiple discord accounts on our discord server to create drama or join our discord events.</li><br></br>
          <li className={styles.overlock_Font}>You will not abuse the Lucent Heart Rebirth Donation shop or Gift System in any way, or use it to spam, scam, or harass other players.</li><br></br>
          <li className={styles.overlock_Font}>You will not reverse or charge back any donation made at the Lucent Heart Rebirth website.
          Any such donation which is reversed or charged back will cause the affected account to be immediately suspended until such time as the reversal or charge back is canceled or revoked.</li><br></br>
          <li className={styles.overlock_Font}>You will not upload to or advertise on the Lucent Heart Rebirth game client, or the official Lucent Heart Rebirth discord server,
          any copyrighted or trademarked content to which you do not own all rights without the express written permission of the author or the copyright or trademark holder.</li><br></br>
          <li className={styles.overlock_Font}>You will not attempt to interfere with, hack into, or decipher any transmissions to or from the servers running the Lucent Heart Rebirth game or website.
          This includes, but is not limited to hacks, cheats or alterations of transmitted information (packets) of any kind.</li><br></br>
          <li className={styles.overlock_Font}>You will not use any third-party program (such as scripts, macros, AI, bots) to automate gameplay functions, including playing, chatting, interacting, gathering gold or items within Lucent Heart Rebirth.
          You will also not assist, relay, or store gold or items for other players who are using these automated processes. The use of any macros, AI, or bots, whether perceived or real, will result in the same punishment.
          This does not apply to the usage of macros for setting up macro keybinds on your mouse to play the game.</li><br></br>
          <li className={styles.overlock_Font}>You will not use any bug or exploit (a flaw, process or oversight which grants the user unnatural or unintended benefits or advantages, or which disrupt or harm normal gameplay for other players)
          in Lucent Heart Rebirth. You will not communicate the existence of the exploit or bug via the Lucent Heart Rebirth game client or through public posting, including on the Lucent Heart Rebirth discord server to any other user of Lucent Heart Rebirth.
          Any bug that is found must be immediately reported, with as many details as possible, through the ticket system at the Lucent Heart Rebirth discord server.</li><br></br>
          <li className={styles.overlock_Font}>You will use the Lucent Heart Rebirth discord to alert the staff to in-game issues such as inappropriate behavior,
          offensive character names, the perceived use of a bot, hacks, exploits or glitch abuse, or other misconduct, as specified in the Lucent Heart Rebirth <a href="/rules">Rules of Conduct</a>t.</li><br></br>
          <li className={styles.overlock_Font}>You will use the Lucent Heart Rebirth Discord to communicate with the staff regarding any concerns you may have.
          You will refrain from causing disruptions or dramas in our public chats, whether it's in the game or on Discord.</li><br></br>
      </ol>

        </div>


      </div>


    </div>
  )
}

export default RulesPage