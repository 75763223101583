import { Button } from 'react-bootstrap';
import useInput from '../../hooks/UseInput';
import axios from 'axios';
import "../../UI/navbar/auth/login.css"
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




/*const alertClicked = () => {
    alert('Account creation is currently disabled. If you wish to create a new account, send in a ticket on our discord server.');
  };*/

const isNotEmpty = (value) => value.trim() !== '';
const isEmail = (value) => value.includes('@');

function Register(props) {
    const navigate = useNavigate()

    //username input
    const {
        value: usernameValue,
        isValid: usernameIsValid,
        hasError: usernameHasError,
        valueChangeHandler: usernameChangeHandler,
        inputBlurHandler: usernameBlurHandler,
        reset: resetUsername
    } = useInput(isNotEmpty)

    //password input
    const {
        value: passwordValue,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        reset: resetPassword
    } = useInput(isNotEmpty)

    //email input
    const {
        value: emailValue,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail
    } = useInput(isEmail)

    let formIsValid = false
    if (usernameIsValid && emailIsValid && passwordIsValid) {
        formIsValid = true
    }

    const submitHandler = async event => {
        event.preventDefault()

        const registerInput = {
            username: usernameValue,
            email: emailValue,
            password: passwordValue
        }

        {/*try {
            const res = await axios.post("/api/auth/register", registerInput)
            console.log(registerInput)
        } catch (error) {
            console.log(error.response?.data)
        }

        if (!formIsValid) return

        resetEmail()
        resetUsername()
        resetPassword()
        navigate()*/}


        const showToast = (type, message) => {
            if (type === "success") {
              toast.success(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
              });
            } else {
              toast.error(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
              });
            }
          };
      
      try {
        const res = await axios.post("/api/auth/register", registerInput)
        if(res.status === 200)
          showToast("success", "Your account has been successfully created.");
        else
          showToast("error", "There seems to have been an error while creating your account.");        
        } catch (error) {
          showToast("error", error.response?.data || "Account creation has failed. Please try again.");        
        }

        try {
            const res = await axios.post("/api/auth/register", registerInput) 
            console.log(registerInput)
        } catch (error) {
            console.log(error.response?.data)
        } 


        if (!formIsValid) return

        resetEmail()
        resetUsername()
        resetPassword()
        navigate()


    }


    const emailClasses = emailHasError ? 'form-control invalid' : 'form-control'
    const usernameClasses = usernameHasError ? 'form-control invalid' : 'form-control'
    const passwordClasses = passwordHasError ? 'form-control invalid' : 'form-control'

    return (
        <div className='centered'>
            <ToastContainer />
            <form onSubmit={submitHandler} className='register-box'>
                <h3 className="register-title">Create New Account</h3>
                <div className='control-group'>
                    <div className={emailClasses}>
                        <input required
                            type="email"
                            name="email"
                            value={emailValue}
                            placeholder='Email'
                            onChange={emailChangeHandler}
                            onBlur={emailBlurHandler}
                        />
                        {emailHasError && <p className="error-text">Please provide a valid Email</p>}
                    </div>
                    <div className={usernameClasses}>
                        <input required
                            type="text"
                            name="username"
                            value={usernameValue}
                            placeholder='Login ID'
                            onChange={usernameChangeHandler}
                            onBlur={usernameBlurHandler}
                        />
                        {usernameHasError && <p className="error-text">Please enter your future Login ID</p>}
                    </div>
                    <div className={passwordClasses}>
                        <input required
                            type="password"
                            name="password"
                            value={passwordValue}
                            placeholder='Password'
                            onChange={passwordChangeHandler}
                            onBlur={passwordBlurHandler}
                        />
                        {passwordHasError && <p className="error-text">Please enter your future Password</p>}
                    </div>
                </div>

                <Button disabled={!formIsValid}
                    onClick={submitHandler}
                    variant="primary"
                    type='submit'>
                    Create New Account
                </Button>

                <br></br>


                <h3 className="register-title5">Login IDs cannot exceed 30 characters.</h3>
<h3 className="register-title5">Passwords cannot exceed 20 characters.</h3>

                {/*<Button disabled={!formIsValid}
                    onClick={alertClicked}
                    variant="primary"
                    type='submit'>
                    Create New Account
    </Button>*/}


            </form>
        </div>
    )
}

export default Register