import React from 'react'
import ImageCarousel from '../../UI/container/newsrow/image-carousel/ImageCarousel'

import styles from "./faq.module.css"


function FAQPage() {
  return (
    <div className={styles.content_column}>
      <ImageCarousel />

      <h2 className={styles.title}>Rebirth FAQ</h2>
      <div className='button_info'>

        <div className={styles.disclaimer}>
          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: What is the Lucent Heart Rebirth Project?
          </h5>

          <p className={styles.overlock_Font}>
            Lucent Heart Rebirth is a <u>Lucent Heart Private Server</u> project that is currently
            being developed by a couple former players that wanted to play the game once again
            without all of the the issues that existed in previous servers (such as SubaGames and
            more recently the A1975 server). You can read our project letter with all of our plans
            and goals <a href='https://docs.google.com/document/d/12nwiGdzeOTusGym6Lu5MNEIfXbnNpuU7/edit' target='_blank'>here</a>.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: Where can I see the differences between Rebirth and other servers like SubaGames?
          </h5>

          <p className={styles.overlock_Font}>
            You can read the differences in our <u>About Page</u>, in our discord server or in
            our <a href='https://docs.google.com/document/d/12nwiGdzeOTusGym6Lu5MNEIfXbnNpuU7/edit' target='_blank'>Project Letter</a>.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: Who is the Staff team running this server?
          </h5>

          <p className={styles.overlock_Font}>
            Our staff is made up of our Game Masters - Hecate, Theia, Cadena, Dionysus - and one technician - Zeus.
            We're all former Lucent Heart players and MMO enthusiasts.
            Many veteran players also provide us their help and input but they are not part of the staff team.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: Where is the server hosted?
          </h5>
<p className={styles.overlock_Font}>
The server is hosted in Germany.
          </p>

<h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: What is the server's timezone?
          </h5>
<p className={styles.overlock_Font}>
It's GMT+2 (Berlin). You can check the server time both in-game and on our website's side bar.
          </p>

<h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: Will you open a server in [insert name of a country here]?
          </h5>
<p className={styles.overlock_Font}>
No. We have no plans to open another server in a different country.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: I don't like the animation bugs in the current 60 FPS client. Can I play with the old 30 FPS one?
          </h5>

          <p className={styles.overlock_Font}>
            Yes, you can. You can get the old 30 FPS client files in our <u>Download page</u>.
            Just replace them with the ones in your game folder before you start the game.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: What are the server's EXP and drop rates?
          </h5>

          <p className={styles.overlock_Font}>
            The EXP rates are the same as previous servers but we have increased the drop rate by x2.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: How fast/slow is the EXP gain?
          </h5>

          <p className={styles.overlock_Font}>
            There are two key points to answer this question: EXP curve and EXP rate.
            <br />
            <br />
            Firstly, our Rebirth server is using an EXP curve similar to the one from the Japanese server,
            which is quite higher than the English (Beanfun and SubaGames) EXP curve, so it can be said that
            it's harder to level.
            <br />
            <br />
            Secondly, our Rebirth server has the same EXP rates as previous servers.
            However, we have something completely new that improves the players' leveling gameplay
            experience: <b>party EXP boost</b>. Unlike previous servers in which the EXP would be split between
            the members of a party, on our server <u>being in a party will actually give the party members more EXP</u>.
            In addition, we have also boosted the EXP given by the Eventure System and the Daily Quests.
            <br />
            <br />
            Therefore, we can say that solo leveling is as hard as it was on the Japanese server if you are only killing monsters and questing, but party
            leveling will be much faster than it was on any other server.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: Will there be new content?
          </h5>

          <p className={styles.overlock_Font}>
            Yes! We have unlocked all of the new maps up to Ver.16, the new Ascetic & Eclipse Master class, new equipment,
            new fashion, new passive and battle pets, etc...
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: Can I create and use multiple accounts?
          </h5>
<p className={styles.overlock_Font}>
Yes, you can, so long as you are not exploiting anything or harassing other players. Be sure to read our <a href="/rules">Rules of Conduct</a> for a better understanding of what you can and can't do.
          </p>

<h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: Can I attend events with multiple accounts?
          </h5>
<p className={styles.overlock_Font}>
Absolutely not. ALTs are not allowed in any event, whether in-game or on discord.
          </p>


          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: What is the cap level?
          </h5>

          <p className={styles.overlock_Font}>
            Our server's max level is Lv100. However, for the server release we have set the max level at Lv45. We have since then increased the level cap, and the current cap is Lv65.
          </p>


          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: Does this server have Item Mall and is pay to win?
          </h5>

          <p className={styles.overlock_Font}>
            Yes, we have an Item Mall. No, our server is not be pay to win. Our Item Mall features shops with both
            cash (donations) and in-game currency (Stardust) payment options.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: Where can I donate?
          </h5>

          <p className={styles.overlock_Font}>
            You can donate on our <a href="/donations">Donations</a> page.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
            Q: What is Stardust and where can I get it?
          </h5>

          <p className={styles.overlock_Font}>
            Stardust is a currency that you can farm in-game and use it to buy items on the Item Mall.
            It is available through Discord Events, Dailies, Eventure System, Team Survival Arena and in other places!
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
          Q: Are the zodiac signs getting revamped?
          </h5>

          <p className={styles.overlock_Font}>
          We plan to tweak and improve the zodiac system and skills in the future to improve gameplay experience.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
          Q: Are the classes gooing to be nerfed or reworked?
          </h5>

          <p className={styles.overlock_Font}>

          We have already implemented several adjustments to all classes and their skills based on player feedback gathered through our Discord forums.
          We continue to take this feedback into consideration as we deploy changes to enhance the gameplay experience.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
          Q: Are there in-game events? What about May and Event Points?
          </h5>

          <p className={styles.overlock_Font}>
          Of course! We regularly host various in-game events throughout the month. These events award Event Points which can be exchanged for various items at the May NPC.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
          Q: Are there Discord-only events?
          </h5>

          <p className={styles.overlock_Font}>
          Yes! We have plenty events and contests on our discord server that will grant you awesome rewards.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
          Q: Does Lucent Heart Rebirth have all passive and battle pets?
          </h5>

          <p className={styles.overlock_Font}>
          We will slowly release the passive and battle pets as time goes by but only the "simple" ones will be available early on. 
          In addition, players receive one free passive and battle pet from the Starry Sky Treasure Box Rewards.
          </p>

          <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
          Q: Does Lucent Heart Rebirth have pet squad?
          </h5>

          <p className={styles.overlock_Font}>
          Not at the moment. We're currently assessing the pet squad feature to ensure that it doesn't disrupt the balance of team dynamics, which is crucial for fostering cooperative gameplay.
          </p>

        </div>


      </div>


    </div>
  )
}

export default FAQPage