import { Button, Dropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import DropdownButton from 'react-bootstrap/DropdownButton';
import gameLogo from "../../images/LucentHeartLogo.png";
import styles from "./navbar.module.css";
import { useContext, useState } from 'react';
import { AuthContext } from '../../context/authContext';
import Login from "./auth/Login";
import { Link, NavLink } from 'react-router-dom';
// <NavLink to="/guides" onClick={preventClick} className={({ isActive }) => `${styles.nav_link} ${isActive && styles.active}`}>Guides</NavLink>


function LCNavbar() {

  const { logout, currentUser } = useContext(AuthContext)

  const preventClick = (event) => {
    //TEMPORARY MEASURE UNTIL OTHER PAGES ARE BUILT
    event.preventDefault()
  }

  return (

    <nav className={styles.navbar}>

      <div id={styles.navbar_left}>
        <Link to="/">
          <img src={gameLogo} alt='logo of lucent heart rebirth' id={styles.navbar_logo} />
        </Link>
      </div>

      <Nav id={styles.navbar_links}>
        <NavLink to="/" className={({ isActive }) => `${styles.nav_link} ${isActive && styles.active}`} >Home</NavLink>
        <NavLink to="/about"  className={({ isActive }) => `${styles.nav_link} ${isActive && styles.active}`}>About</NavLink>
        <NavLink to="/download" className={({ isActive }) => `${styles.nav_link} ${isActive && styles.active}`}>Download</NavLink>
        <NavLink to="/guides" className={({ isActive }) => `${styles.nav_link} ${isActive && styles.active}`}>Guides</NavLink>
        <NavLink to="/rules" className={({ isActive }) => `${styles.nav_link} ${isActive && styles.active}`}>Rules</NavLink>
        <NavLink to="/support" className={({ isActive }) => `${styles.nav_link} ${isActive && styles.active}`}>Support</NavLink>

      </Nav>

      <div className={styles.navbar_right} >

        {currentUser &&
            /*<Button className={styles.username} variant='primary'>{currentUser?.username}</Button>*/

          <Dropdown data-bs-theme="dark">
          <Dropdown.Toggle variant="outline-info" id="dropdown-basic">{currentUser?.username}</Dropdown.Toggle>

          <Dropdown.Menu>
          <Dropdown.Item href="/profile">Change Password</Dropdown.Item>
          <Dropdown.Item href="/profile">Donation History</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
          </Dropdown>
        }

{/* {currentUser &&
            <Button href="/register" className={styles.username} variant='outline-info' onClick={logout}>Sign Up</Button>
        }   

        {/*{currentUser &&
          <Link to="/profile">
            <Button className={styles.username} variant='primary'>{currentUser?.username}</Button>
          </Link>
        }   */}

{/*
        {currentUser &&
          <Button onClick={logout} variant='primary'>Log Out</Button>
        }
      */}

        {!currentUser &&
          <DropdownButton data-bs-theme="dark"
            drop="dropdown-menu-align-responsive-2"
            variant="outline-info"
            title="Login" >
            <Login />
          </DropdownButton>
        }     

        {!currentUser &&
            <Button href="/register" className={styles.username} variant='outline-info'>Sign Up</Button>
        }   


       {/* {!currentUser &&
          <DropdownButton
            drop="start"
            variant="primary"
            title="Login" >
            <Login />
            <Dropdown.Divider />
            <Link to="/register">
              <li className={styles.input_form}>
                Create Account
              </li></Link>
          </DropdownButton>
        } */}

      </div>

    </nav>

  );
}

export default LCNavbar;