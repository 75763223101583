import React, { useContext, useEffect, useState } from 'react'
import ImageCarousel from '../../UI/container/newsrow/image-carousel/ImageCarousel'
import Container from 'react-bootstrap/Container';
import styles from "./guides.module.css"
import Image1 from  "../../../src/images/pete.png"


function AlchemistPage() {

  const [expandedImage, setExpandedImage] = useState(false)
  const expandImage = () => setExpandedImage(!expandedImage)



return (
  <div className={styles.content_column}>
    <ImageCarousel />

    <h2 className={styles.title}>Alchemy Production Quests</h2>
    <div className='button_info'>

      <div className={styles.disclaimer}>

        <p className={styles.overlock_Font2}>Alchemists can craft powerful potions that increase stats, provide HP, MP and other bonuses.
        Every 5 levels they can upgrade their crafting skills by completing production quests.
        </p>
      </div>
    </div>

    <h2 className={styles.title}>Thereall City of Peace</h2>

    <div className={styles.disclaimer}>

      <p className={styles.overlock_Font2}>
      <b>Crafting Level 1 ➙  Character Level 4</b>
      <br /><b>Map:</b> Thereall City of Peace
      <br /><b>NPC:</b> Talk to [Crafting Workshop Administrator] Quincy to enter the Alchemy Workshop and then talk to [Alchemist] Daniel for an introduction into Alchemy Crafting (he will require a 50 gold fee).
      </p>

      <br />

      <p className={styles.overlock_Font2}>
      <b>Crafting Level 2 ➙  Character Level 10</b>
      <br /><b>Map:</b> Hercules Hill <a href='https://i.imgur.com/r0jIeOw.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Thunder Clan Giant (Strong Level 10) - 5 Verbena</li>
      </ul>
      </p>

      <br />

      <p className={styles.overlock_Font2}>
      <b>Crafting Level 3 ➙  Character Level 15</b>
      <br /><b>Map:</b> Herculles Hill <a href='https://i.imgur.com/DWZaqZm.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Sleepy Bear Cub (Strong Level 14) (Use Igniters to summon them from caves) - 5 Pine Cone</li>
      </ul>
      </p>
      <p className={styles.overlock_Font2}><b>Map:</b> Ancient Ruins <a href='https://i.imgur.com/soC6jGo.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Thunder Clan Explorers (Strong Level 15) - 5 Chamomile</li>
      </ul>
      </p>

      <br />

      <p className={styles.overlock_Font2}><b>Crafting Level 4 ➙  Character Level 20</b>
      <br /><b>Map:</b> Ancient Ruins <a href='https://i.imgur.com/GHCGBcO.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Cruel Hunters (Strong Level 20 - Night) - 2 Anise</li>
        <li className={styles.overlock_Font}>Thunder Clan Trappers (Strong Level 20 - Day) - 5 Sage Leaves</li>
      </ul>
      </p>

      <br />
    
      <p className={styles.overlock_Font2}><b>Crafting Level 5 ➙  Character Level 25</b>
      <br /><b>Map:</b> Melody Valley <a href='https://i.imgur.com/TlrEXXE.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Mysterious Valley Flower (General Level 24) - 10 Peppermint</li>
        <li className={styles.overlock_Font}>Allegro Melody (General Level 24) - 20 Thyme</li>
      </ul>
      </p>


    </div> 

    <h2 className={styles.title}>Gold Port City of Trade</h2>
    <div className={styles.disclaimer}>

    <p className={styles.overlock_Font2}><b>Crafting Level 6 ➙  Character Level 30</b>
    <br /><b>Map:</b> Pegasus Field <a href='https://i.imgur.com/51UaRgl.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Patrolling Venomous Bees (General Level 29) - 10 Miasma Powder</li>
      </ul>
      </p>
      <p className={styles.overlock_Font2}><b>Map:</b> Blooming Forest <a href='https://i.imgur.com/8xuzCTp.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Prowling Forest Mic (General Level 30) - 10 Poppy</li>
      </ul>
      </p>
      <p className={styles.overlock_Font2}><b>Map:</b> Golden Sand <a href='https://i.imgur.com/4Ek8116.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Gold Hunter (Strong Level 27 - Day) -  10 Amanita</li>
      </ul>
      </p>
      <br />

      <p className={styles.overlock_Font2}><b>Crafting Level 7 ➙  Character Level 35</b>
      <br /><b>Map:</b> Argos Bay <a href='https://i.imgur.com/KCNxl4o.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Square Sail Helmsman (General Level 35) - 5 Periwinkle</li>
        <li className={styles.overlock_Font}>Old Quarry Sentry (Strong Level 34) - 5 Mandragora Stem Extract</li>
        <li className={styles.overlock_Font}>Old Quarry Recruit (Strong Level 33) - 5 Toadstool</li>
      </ul>
      </p>


    </div>

    <h2 className={styles.title}>Anteacar City of Magic</h2>
    <div className={styles.disclaimer}>

      <p className={styles.overlock_Font2}><b>Crafting Level 8 ➙  Character Level 40</b>
      <br /><b>Map:</b> Whispering Grave <a href='https://i.imgur.com/KCNxl4o.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Mutated Metal (General Level 40 - Day) - 10 Slimy Green Mold</li>
        <li className={styles.overlock_Font}>Mechanized Jailer Model I (Strong Level 37 - Day) - 10 Iris Stem</li>
        <li className={styles.overlock_Font}>Mechanical Jailer Prototype (Strong Level 39 - Day) - 10 Distilled Water</li>
      </ul>
      </p>

      <br />

      <p className={styles.overlock_Font2}><b>Crafting Level 9 ➙  Character Level 45</b>
      <br /><b>Map:</b> Sacred Realm of the God of War <a href='https://i.imgur.com/mz6yR2j.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Malarial Venomous Bees (General Level 42) - 10 Myrtle</li>
        <li className={styles.overlock_Font}>Marshland Man-Eating Flower (General Level 44) - 5 Blood Lily</li>
        <li className={styles.overlock_Font}>Thunder Clan Artisan (Very Strong Level 41 - Day) - 3 Pigeon Blood Stone</li>
      </ul>
      </p>

      <br />

      <p className={styles.overlock_Font2}><b>Crafting Level 10 ➙  Character Level 50</b>
      <br /><b>Map:</b> Coast of Siren <a href='https://i.imgur.com/4tgYG78.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>North Sea Lookout (Strong Level 42) - 15 Elder Flower</li>
        <li className={styles.overlock_Font}>North Sea Marksman (Strong Level 42) - 15 Carnation</li>
        <li className={styles.overlock_Font}>North Sea Ordnance Officer (Very Strong Level 45) - 15 Rose Hip</li>
      </ul>
      </p>



    </div>

    <h2 className={styles.title}>Well of Silence - Black Market</h2>
    <div className={styles.disclaimer}>
    <p className={styles.overlock_Font2}>The following quests are picked up from <b><i>[Master Craftsperson] Old Pete</i></b> located in the Black Market of <b>Well of Silence</b>.</p>
    <div className={styles.imageContainer}>
                <img
                    onClick={expandImage}
                    className={styles.smallImage}
                    src={Image1} />

                {expandedImage &&
                    <div className={styles.overlay} onClick={expandImage}>
                        <img
                            className={styles.fullscreenImage}
                            src={Image1}
                        />
                    </div>
                }
            </div>
        <br /><p className={styles.overlock_Font2}>Once you've gathered all quest materials you must go to a crafting workshop in order to craft the quest items, and then come back to Well of Silence to report.</p>
        <br />
      <p className={styles.overlock_Font2}><b>Crafting Level 11 ➙  Character Level 55</b>
      <br /><b>Map:</b> Stormy Shore <a href='https://i.imgur.com/I83ANkY.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Weak Souls (Very Strong Level 54 - Night) - 10 Twisted Catalyst</li>
      </ul>
      </p>
      <p className={styles.overlock_Font2}><b>Map:</b> Well of Silence <a href='https://i.imgur.com/TBOrihP.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Outlaw Bodyguard (General Level 53) - 15 Adhesive</li>
      </ul>
      </p>

      <br />

      <p className={styles.overlock_Font2}><b>Crafting Level 12 ➙  Character Level 60</b>
      <br /><b>Map:</b> Icarus Rift <a href='https://i.imgur.com/oG9QXZu.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Winged Menders (General Level 60) - 15 Blood of Icarus</li>
        <li className={styles.overlock_Font}>Winged Artisans (General Level 62) - 10 Craftsman's Beverage</li>
      </ul>
      </p>

      <br />

      <p className={styles.overlock_Font2}><b>Crafting Level 13 ➙  Character Level 65</b>
      <br /><b>Map:</b> Heart of Fire <a href='https://i.imgur.com/xO7a9Ug.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Prowling Digger Beast (General Level 66) - 5 Heat Resistant Air Sack</li>
        <li className={styles.overlock_Font}>Hammerstone Demolition Machine (General Level 67) - 5 Insulation Metal</li>
      </ul>
      </p>
      <p className={styles.overlock_Font2}><b>Map:</b> Hephaestus Tableland <a href='https://i.imgur.com/OLAVEbf.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Ash Element (Very Strong Level 70) - 1 Flame Core</li>
      </ul>
      </p>


    </div>

    <h2 className={styles.title}>Minotsize City of Industry</h2>
    <div className={styles.disclaimer}>
    <p className={styles.overlock_Font2}><b>Crafting Level 14 ➙  Character Level 70</b>
    <br /><b>Map:</b> Titan Ruin <a href='https://i.imgur.com/jOs1RcJ.png'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Skeleton Devourer (General Level 72) - 10 Hunter's Feather</li>
      </ul>
      </p>

      <p className={styles.overlock_Font2}><b>Map:</b> Iron Fortress <a href='https://i.imgur.com/5dsGOdi.png'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Flame Element (Strong Level 72 - Day) - 10 Boiling Blood</li>
      </ul>
      </p>


    </div>

    <h2 className={styles.title}>Crystalia City of Military</h2>
    <div className={styles.disclaimer}>
    <p className={styles.overlock_Font2}><b>Crafting Level 15 ➙  Character Level 75</b>
    <br /><b>Map:</b> Moon Fang Island <a href='https://i.imgur.com/cVM5IL2.png'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Grassland Sprout (Strong Level 78) - 5 Grass Seedlings</li>
        <li className={styles.overlock_Font}>Grass Frog (General Level 77) - 5 Speedy Frog Toe</li>
      </ul>
      </p>


    </div>


    
  </div>
)
}

export default AlchemistPage