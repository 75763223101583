import { Button } from 'react-bootstrap';
import useInput from '../../hooks/UseInput';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const isNotEmpty = (value) => value.trim() !== '';

function ChangePassword(props) {
    //oldPassword input
    const {
        value: oldPasswordValue,
        isValid: oldPasswordIsValid,
        hasError: oldPasswordHasError,
        valueChangeHandler: oldPasswordChangeHandler,
        inputBlurHandler: oldPasswordBlurHandler,
        reset: resetoldPassword
    } = useInput(isNotEmpty)

    //newPassword input
    const {
        value: newPasswordValue,
        isValid: newPasswordIsValid,
        hasError: newPasswordHasError,
        valueChangeHandler: newPasswordChangeHandler,
        inputBlurHandler: newPasswordBlurHandler,
        reset: resetnewPassword
    } = useInput(isNotEmpty)

    let formIsValid = oldPasswordIsValid && newPasswordIsValid

    const submitHandler = async event => {
        event.preventDefault()

        const registerInput = {
            oldPassword: oldPasswordValue,
            newPassword: newPasswordValue
        }


        try {
            const res = await axios.post("/api/auth/change-password", registerInput)
            if(res.status === 200)
              showToast("success", "Your password has been successfully updated.");
            else
              showToast("error", "There was an error while updating your password.");        
            } catch (error) {
              showToast("error", error.response?.data || "There was an error while updating your password.");        
            }


        try {
            await axios.post("/api/auth/change-password", registerInput)
        } catch (error) {
            console.log(error.response?.data)
        }

        if (!formIsValid) return

        resetnewPassword()
        resetoldPassword()
    }

    const oldPasswordClasses = oldPasswordHasError ? 'form-control invalid' : 'form-control'
    const newPasswordClasses = newPasswordHasError ? 'form-control invalid' : 'form-control'

    const showToast = (type, message) => {
        if (type === "success") {
          toast.success(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
      };

    return (
        <form onSubmit={submitHandler} className='register-box2'><ToastContainer />
            <div className='control-group'>
                <div className={oldPasswordClasses}>
                    <input required
                        type="password"
                        name="oldPassword"
                        value={oldPasswordValue}
                        placeholder='Current Password'
                        onChange={oldPasswordChangeHandler}
                        onBlur={oldPasswordBlurHandler}
                    />
                    {oldPasswordHasError && <p className="error-text">Please enter your current password</p>}
                </div>
                <div className={newPasswordClasses}>
                    <input required
                        type="password"
                        name="newPassword"
                        value={newPasswordValue}
                        placeholder='New Password'
                        onChange={newPasswordChangeHandler}
                        onBlur={newPasswordBlurHandler}
                    />
                    {newPasswordHasError && <p className="error-text">Please enter your new password</p>}
                </div>
            </div>

            <Button disabled={!formIsValid}
                onClick={submitHandler}
                variant="primary"
                type='submit'>
                Change Password
            </Button>
        </form>
    )
}

export default ChangePassword