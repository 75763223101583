import './App.css';
import { Routes, Route } from "react-router-dom";
import "./fonts.css"

import LCNavbar from './UI/navbar/Navbar';
import HomePage from './pages/home/HomePage';
import Register from './pages/register/Register';
import ErrorPage from './pages/errorpage/ErrorPage';
import IsAnon from './auth/IsAnon';
import IsPrivate from './auth/IsPrivate';
import Construction from './pages/underConstruction/Construction';
import AccountSuccess from './pages/AccountSuccess/accsuccess';
import AccountFail from './pages/AccountFail/accfail';



function App() {
  return (
    <>
      <div>
      <LCNavbar />
        <Routes>
          <Route path="/guides" element={<HomePage/>}/>
          <Route path="/register" element={<IsAnon><Register /></IsAnon>} />
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<HomePage/>}/>
          <Route path="/download" element={<IsPrivate><HomePage /></IsPrivate>} />
          <Route path="/donations" element={<IsPrivate><HomePage /></IsPrivate>} />
          <Route path="/profile" element={<IsPrivate><HomePage /></IsPrivate>} />
          <Route path="/faq" element={<HomePage/>}/>
          <Route path="/guides" element={<Construction/>}/>
          <Route path="/support" element={<HomePage/>}/>
          <Route path="/rules" element={<HomePage/>}/>
          <Route path="/guides/blacksmith" element={<HomePage/>}/>
          <Route path="/guides/tailor" element={<HomePage/>}/>
          <Route path="/guides/jeweler" element={<HomePage/>}/>
          <Route path="/guides/mechanic" element={<HomePage/>}/>
          <Route path="/guides/alchemist" element={<HomePage/>}/>
          <Route path="/guides/card-designer" element={<HomePage/>}/>
          <Route path="/guides/eventure-system" element={<HomePage/>}/>
          {/* Error page for unexisting routes */}
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
