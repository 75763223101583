import styles from "./errorpage.module.css"

const imgURL = "https://education-team-2020.s3.eu-west-1.amazonaws.com/web-dev/m3/react-routing/404.gif";

function ErrorPage() {
  return (
    <div className={styles.error_page}>
      <h1>This page doesn't exist.</h1>
      <img src={imgURL} alt="404 error gif" className={styles.page_img} />
    </div>
  )
}

export default ErrorPage
