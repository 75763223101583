import axios from 'axios'
import { React, createContext, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext()

export const AuthContextProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null)

    const login = async (loginInput) => {
        const res = await axios.post("/api/auth/login", loginInput)
        setCurrentUser(res.data)
    }

    const navigate = useNavigate()
    const logout = async (loginInput) => {
        await axios.post("/api/auth/logout")
        .then(() =>  {
            setCurrentUser(null)
            navigate('')
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                setCurrentUser(null)
            }
        })
    }

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(currentUser))
    }, [currentUser])

    return (
        <AuthContext.Provider value={{currentUser, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}