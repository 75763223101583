import React, { useContext, useEffect, useState } from 'react'
import ImageCarousel from '../../UI/container/newsrow/image-carousel/ImageCarousel'
import Container from 'react-bootstrap/Container';
import styles from "./guides.module.css"
import Image1 from  "../../../src/images/pete.png"


function MechanicPage() {

  const [expandedImage, setExpandedImage] = useState(false)
  const expandImage = () => setExpandedImage(!expandedImage)



return (
  <div className={styles.content_column}>
    <ImageCarousel />

    <h2 className={styles.title}>Mechanic Production Quests</h2>
    <div className='button_info'>

      <div className={styles.disclaimer}>

        <p className={styles.overlock_Font2}>Mechanics can craft a vast variety of bullets, tactical tools, explosives and use them in and outside combat.
        Every 5 levels they can upgrade their crafting skills by completing production quests.
        </p>
      </div>
    </div>

    <h2 className={styles.title}>Thereall City of Peace</h2>

    <div className={styles.disclaimer}>

      <p className={styles.overlock_Font2}>
      <b>Crafting Level 1 ➙  Character Level 4</b>
      <br /><b>Map:</b> Thereall City of Peace
      <br /><b>NPC:</b> Talk to [Crafting Workshop Administrator] Quincy to enter the Machine Workshop and then talk to [Mechanic] Sheila for an introduction into Machinery Crafting (she will require a 50 gold fee).
      </p>

      <br />

      <p className={styles.overlock_Font2}>
      <b>Crafting Level 2 ➙  Character Level 10</b>
      <br /><b>Map:</b> Breezing Land <a href='https://i.imgur.com/waIKPMw.png'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Floating Mask (General Level 8) - 3 Copper Plate</li>
        <li className={styles.overlock_Font}>Mysterious Puppet (General Level 10) - 2 Gearwheel</li>
      </ul>
      </p>

      <br />

      <p className={styles.overlock_Font2}>
      <b>Crafting Level 3 ➙  Character Level 15</b>
      <br /><b>Map:</b> Herculles Hill <a href='https://i.imgur.com/DWZaqZm.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Sleepy Bear Cub (Strong Level 14) (Use Igniters to summon them from caves) - 5 Black Charcoal</li>
      </ul>
      </p>
      <p className={styles.overlock_Font2}><b>Map:</b> Ancient Ruins <a href='https://i.imgur.com/soC6jGo.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Thunder Clan Explorers (Strong Level 15) - 5 Dynamite</li>
      </ul>
      </p>

      <br />

      <p className={styles.overlock_Font2}><b>Crafting Level 4 ➙  Character Level 20</b>
      <br /><b>Map:</b> Ancient Ruins <a href='https://i.imgur.com/gYEDYyL.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Lowland Frogs (General Level 20) - 5 Hot Glue</li>
        <li className={styles.overlock_Font}>Thunder Clan Trapper (Strong Level 20 - Day) - 5 Refraction Glass</li>
      </ul>
      </p>

      <br />
    
      <p className={styles.overlock_Font2}><b>Crafting Level 5 ➙  Character Level 25</b>
      <br /><b>Map:</b> Melody Valley <a href='https://i.imgur.com/sUsxbKv.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Mysterious Valley Flower (General Level 24) - 20 Iron Sheet</li>
        <li className={styles.overlock_Font}>Allegro Melody (General Level 24) - 10 Fuel</li>
      </ul>
      </p>


    </div> 

    <h2 className={styles.title}>Gold Port City of Trade</h2>
    <div className={styles.disclaimer}>

    <p className={styles.overlock_Font2}><b>Crafting Level 6 ➙  Character Level 30</b>
    <br /><b>Map:</b> Pegasus Field <a href='https://i.imgur.com/uMbGUOS.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Agile Thief (General Level 28) - 10 Carbon Rod</li>
        <li className={styles.overlock_Font}>Gold Thief (General Level 27) - 10 Brass Plate</li>
      </ul>
      </p>
      <p className={styles.overlock_Font2}><b>Map:</b> Blooming Forest <a href='https://i.imgur.com/8xuzCTp.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Prowling Forest Mic (General Level 30) - 10 Zinc Ore</li>
      </ul>
      </p>
      <br />

      <p className={styles.overlock_Font2}><b>Crafting Level 7 ➙  Character Level 35</b>
      <br /><b>Map:</b> Argos Bay <a href='https://i.imgur.com/KCNxl4o.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Square Sail Helmsman (General Level 35) - 5 Iron Powder</li>
        <li className={styles.overlock_Font}>Old Quarry Sentry (Strong Level 34) - 1 Carbon</li>
        <li className={styles.overlock_Font}>Old Quarry Recruit (Strong Level 33) - 5 Coarse Salt</li>
      </ul>
      </p>


    </div>

    <h2 className={styles.title}>Anteacar City of Magic</h2>
    <div className={styles.disclaimer}>

      <p className={styles.overlock_Font2}><b>Crafting Level 8 ➙  Character Level 40</b>
      <br /><b>Map:</b> Whispering Grave <a href='https://i.imgur.com/Doe9s2p.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Mutated Metal (General Level 40 - Day) - 10 Sulfuric Acid</li>
        <li className={styles.overlock_Font}>Mechanized Jailer Model I (Strong Level 37 - Day) - 10 Yellow Phosphorus</li>
        <li className={styles.overlock_Font}>Mechanical Jailer Prototype (Strong Level 39 - Day) - 10 Machine Oil</li>
      </ul>
      </p>

      <br />

      <p className={styles.overlock_Font2}><b>Crafting Level 9 ➙  Character Level 45</b>
      <br /><b>Map:</b> Sacred Forest <a href='https://i.imgur.com/OcOYwTZ.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Rebellious Recruit (Strong Level 42) - 1 Damaged Motor</li>
        <li className={styles.overlock_Font}>Rebellious Sentry (Strong Level 42) - 1 Damaged Handle</li>
        <li className={styles.overlock_Font}>Rebellious Musketeer (Very Strong Level 43) - 1 Damaged Water Tank​</li>
      </ul>
      </p>

      <br />

      <p className={styles.overlock_Font2}><b>Crafting Level 10 ➙  Character Level 50</b>
      <br /><b>Map:</b> Coast of Siren <a href='https://i.imgur.com/ftZh5HD.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Reef Singers (Strong Level 42) - 15 Refraction Crystal</li>
        <li className={styles.overlock_Font}>Reef Mermaids (Strong Level 42) - 15 Crystal Snowflake</li>
        <li className={styles.overlock_Font}>Reef Guards (Very Strong Level 44) - 15 Steel Plate</li>
      </ul>
      </p>



    </div>

    <h2 className={styles.title}>Well of Silence - Black Market</h2>
    <div className={styles.disclaimer}>
    <p className={styles.overlock_Font2}>The following quests are picked up from <b><i>[Master Craftsperson] Old Pete</i></b> located in the Black Market of <b>Well of Silence</b>.</p>
    <div className={styles.imageContainer}>
                <img
                    onClick={expandImage}
                    className={styles.smallImage}
                    src={Image1} />

                {expandedImage &&
                    <div className={styles.overlay} onClick={expandImage}>
                        <img
                            className={styles.fullscreenImage}
                            src={Image1}
                        />
                    </div>
                }
            </div>
        <br /><p className={styles.overlock_Font2}>Once you've gathered all quest materials you must go to a crafting workshop in order to craft the quest items, and then come back to Well of Silence to report.</p>
        <br />
        <p className={styles.overlock_Font2}><b>Crafting Level 11 ➙  Character Level 55</b>
      <br /><b>Map:</b> Stormy Shore <a href='https://i.imgur.com/I83ANkY.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Weak Souls (Very Strong Level 54 - Night) - 10 Twisted Energy</li>
      </ul>
      </p>
      <p className={styles.overlock_Font2}><b>Map:</b> Well of Silence <a href='https://i.imgur.com/TBOrihP.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Outlaw Bodyguard (General Level 53) - 10 Unstable Iron Plate</li>
      </ul>
      </p>

      <br />

      <p className={styles.overlock_Font2}><b>Crafting Level 12 ➙  Character Level 60</b>
      <br /><b>Map:</b> Icarus Rift <a href='https://i.imgur.com/oG9QXZu.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Winged Menders (General Level 60) - 15 Blood of Icarus</li>
        <li className={styles.overlock_Font}>Winged Artisans (General Level 62) - 10 Craftsman's Blade</li>
      </ul>
      </p>

      <br />

      <p className={styles.overlock_Font2}><b>Crafting Level 13 ➙  Character Level 65</b>
      <br /><b>Map:</b> Heart of Fire <a href='https://i.imgur.com/h4bRmZk.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Prowling Digger Beast (General Level 66) - 5 Heat Resistant Air Sack</li>
        <li className={styles.overlock_Font}>Turbine Survey Machine (General Level 68) - 5 Insulation Metal</li>
      </ul>
      </p>
      <p className={styles.overlock_Font2}><b>Map:</b> Hephaestus Tableland <a href='https://i.imgur.com/OLAVEbf.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Ash Element (Very Strong Level 70) - 1 Flame Core</li>
      </ul>
      </p>


    </div>

    <h2 className={styles.title}>Minotsize City of Industry</h2>
    <div className={styles.disclaimer}>
    <p className={styles.overlock_Font2}><b>Crafting Level 14 ➙  Character Level 70</b>
    <br /><b>Map:</b> Titan Ruin <a href='https://i.imgur.com/OLAVEbf.jpg'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Skeleton Devourer (General Level 72) - 10 Hunter's Feather</li>
      </ul>
      </p>

      <p className={styles.overlock_Font2}><b>Map:</b> Iron Fortress <a href='https://i.imgur.com/v609YUp.png'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Thunder Clan Giant Warrior (Strong Level 73) - 10 Thunder Clan's Parasite</li>
      </ul>
      </p>


    </div>

    <h2 className={styles.title}>Crystalia City of Military</h2>
    <div className={styles.disclaimer}>
    <p className={styles.overlock_Font2}><b>Crafting Level 15 ➙  Character Level 75</b>
    <br /><b>Map:</b> Crescent Island <a href='https://i.imgur.com/nH7WfnO.png'>[Map Location]</a>
      <ul>
        <li className={styles.overlock_Font}>Old Peg Leg (Strong Level 76) - 5 Crescent Iron</li>
        <li className={styles.overlock_Font}>Pirate Crescent Archer (Strong Level 78) - 5 Crescent Silver</li>
      </ul>
      </p>


    </div>


    
  </div>
)
}

export default MechanicPage