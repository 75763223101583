import React from 'react'
import NewsRow from "./newsrow/row/NewsRow"
import Sidebar from './sidebar/Sidebar'
import { useLocation } from 'react-router-dom'
import Download from '../../pages/Download/Download'
import Donations from '../../pages/Donations/Donations'
import Profile from '../../pages/Profile/Profile'
import AboutPage from '../../pages/AboutPage/AboutPage'
import styles from "./container.module.css"
import FAQPage from '../../pages/faq_page/FAQPage'
import SupportPage from '../../pages/support/SupportPage'
import RulesPage from '../../pages/rules/RulesPage'
import GuidesPage from '../../pages/guides/GuidesPage'
import BlacksmithPage from '../../pages/guides/BlacksmithPage'
import TailorPage from '../../pages/guides/TailorPage'
import JewelerPage from '../../pages/guides/JewelerPage'
import MechanicPage from '../../pages/guides/MechanicPage'
import AlchemistPage from '../../pages/guides/AlchemistPage'
import CardDesignerPage from '../../pages/guides/CardDesignerPage'
import EventurePage from '../../pages/guides/EventurePage'

function Container() {
    const { pathname } = useLocation()

    return (
        <div id={styles.container_box}>
            <div id={styles.container_content}>
                {pathname === "/" && <NewsRow />}
                {pathname === "/download" && <Download />}
                {pathname === "/donations" && <Donations />}
                {pathname === "/profile" && <Profile />}
                {pathname === "/about" && <AboutPage/>}
                {pathname === "/faq" && <FAQPage/>}
                {pathname === "/support" && <SupportPage/>}
                {pathname === "/rules" && <RulesPage/>}
                {pathname === "/guides" && <GuidesPage/>}
                {pathname === "/guides/blacksmith" && <BlacksmithPage/>}
                {pathname === "/guides/tailor" && <TailorPage/>}
                {pathname === "/guides/jeweler" && <JewelerPage/>}
                {pathname === "/guides/mechanic" && <MechanicPage/>}
                {pathname === "/guides/alchemist" && <AlchemistPage/>}
                {pathname === "/guides/card-designer" && <CardDesignerPage/>}
                {pathname === "/guides/eventure-system" && <EventurePage/>}
                <Sidebar />
            </div>
        </div>
    )
}

export default Container