import React from 'react'
import ImageCarousel from '../../UI/container/newsrow/image-carousel/ImageCarousel'

import styles from "./about.module.css"


function AboutPage() {
  return (
    <div className={styles.content_column}>
      <ImageCarousel />

      <h2 className={styles.title}>Lucent Heart</h2>
      <div className='button_info'>

        <div className={styles.disclaimer}>

          <p className={styles.overlock_Font}>
            Lucent Heart is an anime styled MMORPG based on greek mythology that
            offers you many unique features such as crafting system, zodiac system,
            race system, house system, cupid system, among many others.
          </p>

          <p className={styles.overlock_Font}>
            The game takes place in the world of Acadia and the story focuses on the war
            between the two twin Goddesses Theia and Cadena. The players are the Divine
            Realm's protectors and it's their duty to stop Cadena's dark forces and bring an
            end to the endless war.
          </p>
        </div>
      </div>

      <h2 className={styles.title}>Lucent Heart Rebirth</h2>

      <div className={styles.disclaimer}>

        <p className={styles.overlock_Font}>
          Lucent Heart Rebirth is a Lucent Heart <u>Private Server</u> hosted by a small but dedicated group of veteran
          players that wanted to play the game once again without all of the issues that existed in previous servers. Our team aims to create an unique and unforgettable
          Lucent Heart experience and to reach that goal we've made some changes and improvements to the game
          which you can read down below.
        </p>
        
        <ul>
          <li className={styles.overlock_Font}>New 60 FPS client</li>
          <li className={styles.overlock_Font}>New maximum level set to Lvl 100</li>
          <li className={styles.overlock_Font}>New Ascetic and Eclipse Master Classes</li>
          <li className={styles.overlock_Font}>New Regions and Maps</li>
          <li className={styles.overlock_Font}>New Starting Map (Astrovillage)</li>
          <li className={styles.overlock_Font}>New faces, hairstyles and hair colors in characte creation</li>
          <li className={styles.overlock_Font}>New mounts, battle pets and passive pets</li>
          <li className={styles.overlock_Font}>New items, furniture and outfits</li>
          <li className={styles.overlock_Font}>New Dance Master and Dance PK songs/dances</li>
          <li className={styles.overlock_Font}>New Guild EXP levels</li>
          <li className={styles.overlock_Font}>New Starry Sky Gift Boxes & Online Time Rewards</li>
          <li className={styles.overlock_Font}>New EXP curve</li>
          <li className={styles.overlock_Font}>Party EXP Boost</li>
          <li className={styles.overlock_Font}>Stardust System</li>
          <li className={styles.overlock_Font}>Eventure System</li>
          <li className={styles.overlock_Font}>Training Grounds</li>
          <li className={styles.overlock_Font}>PvP Battle Arena [currently disabled]</li>
          <li className={styles.overlock_Font}>Team Survival Arena</li>
          <li className={styles.overlock_Font}>Basic Character Creation count changed from 2 to 3</li>
          <li className={styles.overlock_Font}>Basic Backpack Space from 48 to 60</li>
          <li className={styles.overlock_Font}>Basic Warehouse Space from 48 to 60</li>
          <li className={styles.overlock_Font}>Basic OEM amount changed from 3 to 6</li>
          <li className={styles.overlock_Font}>Basic Sell Stall amount changed from 3 to 6</li>
          <li className={styles.overlock_Font}>Basic Wanted Stall amount changed from 3 to 6</li>
          <li className={styles.overlock_Font}>Basic Friend list amount changed from 36 to 50</li>
          <li className={styles.overlock_Font}>Basic Soulmate count changed from 3 to 4</li>
          <li className={styles.overlock_Font}>Mail limit amount changed from 50 to 70</li>
          <li className={styles.overlock_Font}>Maximum amount of quests accepted changed from 30 to 50</li>
          <li className={styles.overlock_Font}>Character deletion time changed from 3 days to 1 day</li>
          <li className={styles.overlock_Font}>Increased Dance PK points reward by 50%</li>
          <li className={styles.overlock_Font}>Increased the amount of World Boss spawns from 2 to 3</li>
          <li className={styles.overlock_Font}>Increased the amount of Race Points rewarded per race in all Racing Modes</li>
          <li className={styles.overlock_Font}>Increased the amount of coupon rewards given by Dailies</li>
          <li className={styles.overlock_Font}>Increased all things guild related (Staff, Members, Money, Warehouse Space, etc.)</li>
          <li className={styles.overlock_Font}>Updated all in-game shops (May, Dailies, KM, DM, Race, Cupid, etc.)</li>
          <li className={styles.overlock_Font}>Updated the Blessing System</li>
        </ul>

        <p className={styles.overlock_Font}>
          For more information about our server's features and changes you can read 
          our <a href='https://docs.google.com/document/d/12nwiGdzeOTusGym6Lu5MNEIfXbnNpuU7/edit' target='_blank'>Project Letter</a>.
        </p>


      </div> 


      
    </div>
  )
}

export default AboutPage