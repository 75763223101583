import { useContext } from "react"
import { AuthContext } from "../context/authContext"
import { Navigate } from "react-router-dom"

function IsAnon({children}) {
    const {currentUser} = useContext(AuthContext)

    //if the user is logged in - return to the home page - no access to register page
    if (currentUser) {
        return <Navigate to="/"/>
    //if the user is not logged in, allow to see the register page
    } else {
        return children
    }
}

export default IsAnon