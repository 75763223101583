import { useContext } from "react"
import { AuthContext } from "../context/authContext"
import { Navigate } from "react-router-dom"
import { Alert } from "react-bootstrap"

function IsPrivate({ children }) {
    const { currentUser } = useContext(AuthContext)
    //if user is logged in - allow to see the page
    if (currentUser) {
        return children
    } else {
        return <Alert><br />You must be logged in to access this page.</Alert>
    }
}

export default IsPrivate