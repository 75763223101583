import React, { useState, useEffect } from 'react';
import styles from '../sidebarBox.module.css';

function Clock() {
  const [date, setDate] = useState(new Date());

  function refreshClock() {
    setDate(new Date());
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  return (
    <div className={styles.sidebar_box}>
      <h3 className={styles.sidebar_box_title}>SERVER TIME</h3>
      <div className={styles.sidebar_box_info_div}>
        <div className={styles.clock_image_wrapper}>
          <h3>{date.toLocaleTimeString('de', { timeZone:'Europe/Berlin', hour24: true })}</h3>
        </div>
      </div>
    </div>
  );
}

export default Clock;
