import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './context/authContext';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios'

axios.defaults.withCredentials = true;

const currentVersion = "1";
const storedVersion = localStorage.getItem("version");

if(!storedVersion || storedVersion !== currentVersion)
{
    const user = localStorage.getItem("user")

    if(user)
    {
        localStorage.setItem("user", null);
        //await axios.post("http://localhost:5005/auth/logout")
    }

    localStorage.clear();
    localStorage.setItem("version", currentVersion);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <AuthContextProvider>
            <App />
        </AuthContextProvider>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
