import React, { useEffect, useState } from 'react'
import ImageCarousel from '../image-carousel/ImageCarousel'
import NewsCard from '../NewsCard/NewsCard'
import NewsInput from '../add-new-data/NewsInput'
import axios from 'axios'
import Welcome from '../../../welcome/Welcome'
import styles from "../../container.module.css"
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import "../../../../pagination.scss"

function NewsRow(props) {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    const fetchAllPosts = async () => {
      try {
        const res = await axios.get("/api")
        setPosts(res?.data);
      } catch (error) {
        console.log(error)
      }
    }

    fetchAllPosts()
  }, [posts.length])

  //save new entries when adding new post on site
  const saveNewEntryHandler = (enteredData) => setPosts(prevPosts => [enteredData, ...prevPosts])

  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1)
  const recordsPerPage = 4
  const numberOfPages = Math.ceil(posts.length / recordsPerPage)
  const lastIndex = currentPage * recordsPerPage
  const firstIndex = lastIndex - recordsPerPage
  const paginatedPosts = posts.slice(firstIndex, lastIndex)
  /*const numbers = [...Array(numberOfPages + 1).keys()]
    .slice(1)*/

  const maxElements = 12;
  const numbers = [...Array(numberOfPages + 1).keys()]
    .slice(1)
    .slice(0, Math.min(maxElements, numberOfPages));

  /*const maxElements = 10;
  const numbers = [...Array(numberOfPages + 1).keys()]
    .slice(1)
    .slice(currentPage - 1, Math.min(currentPage - 1 + maxElements, numberOfPages));*/
  
  /*const maxElements = 10;
  const numbers = [...Array(numberOfPages + 1).keys()]
    .slice(1)
    .slice(0, Math.min(maxElements, numberOfPages));*/
  
  
  /*const maxElements = 10 + currentPage - 1;
  const numbers = [...Array(numberOfPages + 1).keys()]
    .slice(1)
    .slice(0, Math.min(maxElements, numberOfPages));*/


  const prevPage = () => { if (currentPage !== 1) setCurrentPage(currentPage - 1) }
  const changeCurrentPage = (id) => setCurrentPage(id)
  const nextPage = () => { if (currentPage !== numberOfPages) setCurrentPage(currentPage + 1) }


  return (
    <div className={styles.content_column} >
      <ImageCarousel />
      <Welcome />

      <NewsInput onSaveNewEntry={saveNewEntryHandler} fullList={posts} />

      {paginatedPosts.map((object) => (
        <NewsCard
          key={object.id}
          id={object.id}
          title={object.title}
          date={object.date}
          description={object.description}
          image={object.img} />
      ))}

      <Pagination>

        <PaginationItem
          className={'paginationItemStyle'}>
          <PaginationLink onClick={prevPage}>Previous</PaginationLink>
        </PaginationItem>

        {numbers.map((i, key) => (
          <PaginationItem
            key={key}
            className={'paginationItemStyle'}
            active={currentPage === i }>
            <PaginationLink onClick={() => changeCurrentPage(i)}>
              {i}
            </PaginationLink>
          </PaginationItem>
        ))}

        <PaginationItem
          className={'paginationItemStyle'}>
          <PaginationLink onClick={nextPage}>Next</PaginationLink>
        </PaginationItem>

      </Pagination>

    </div>
  )
}

export default NewsRow