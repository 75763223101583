import React, { useContext, useState } from 'react'
import Card from 'react-bootstrap/Card';
import styles from "./newscard.module.css"
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthContext } from '../../../../context/authContext';
import axios from 'axios';


function NewsCard(props) {
    const { currentUser } = useContext(AuthContext)

    const formattedDate = new Date(props.date).toLocaleDateString("en-GB")

    const deletePost = async () => {
        const postId = props.id

        try {
            console.log(`post number ${postId}`)
            await axios.delete(`/api/delete/${postId}`)
            console.log(`deleted post with id = ${postId}. title = ${props.title}`)

        } catch (error) {
            console.log(error)
        }
    }

    const getImageSizeClass = () => {
        if (!props.image) return styles.newscard_image_default

        const img = new Image()
        img.src = props.image

        if (img.width > img.height) return styles.newscard_image_wide
    }

    return (
        <div className={styles.newscard_wrapper} >
            <div className={styles.newscard_card} >
                <div className={styles.newscard_title}>
                    <div className={styles.card_deleteIcon}>
                        {props.title}
                        {currentUser?.role === "admin" && <DeleteIcon className={styles.deleteIcon} onClick={deletePost} />}
                    </div>
                </div>
                <div className={styles.date} >
                    {formattedDate}
                </div>
                {props.image &&
                    <img variant="top" src={props.image} className={`${styles.newscard_image} ${getImageSizeClass()}`} />
                }
                <div className={styles.text} >
                    {props.description}
                </div>
            </div>
        </div>
    )
}

export default NewsCard
