import React from 'react'
import styles from "../sidebarBox.module.css"
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import donateButton from "../../../../images/donate_button.png"
import discordButton from "../../../../images/discord_button.png"
import faqButton from "../../../../images/FAQ_button.png"
import youtubeButton from "../../../../images/youtube_button.png"
import { Link } from 'react-router-dom';

function SidebarButton() {
    return (
        <div className={styles.sidebar_box}>
            {/* <div className={styles.faq_box}>
                <p style={{color: "white"}}> FAQ <SmsOutlinedIcon/></p>
            </div>

            <div className={styles.image_box}>
                <img src="https://aurakingdom.to/images/r-invite.jpg"/>
            </div> */}

            <div className={styles.image_box}>
                <Link to='/donations'><img src={donateButton}/></Link>
            </div>

            <div className={styles.image_box}>
                <Link to="/faq"><img src={faqButton}/></Link>
            </div>

            <div className={styles.image_box}>
                <a href='https://discord.com/invite/qPrex9Ectg' target='_blank'><img src={discordButton}/></a>
            </div>

            <div className={styles.image_box}>
                <a href='https://www.youtube.com/@LucentHeartRebirth' target='_blank'><img src={youtubeButton}/></a>
            </div>
        </div>
    )
}

export default SidebarButton