import styles from "../errorpage/errorpage.module.css"

const imgURL = "https://static.wixstatic.com/media/141bfd_f750b94d365a406f9bf5cf080d97c1c2~mv2.gif";

function Construction() {
  return (
    <div className={styles.error_page}>
      <img src={imgURL} alt="404 error gif" className={styles.page_img} />
    </div>
  )
}

export default Construction
