import { useLocation } from "react-router-dom"
import Container from "../../UI/container/Container"
import Sidebar from "../../UI/container/sidebar/Sidebar"
import Welcome from "../../UI/welcome/Welcome"


function HomePage() {
    const {pathname} = useLocation()

    return (
        <>
            <div className="container-content">
            <Container />
            
            </div>
        </>
    )
}

export default HomePage