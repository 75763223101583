import React, { useContext, useEffect, useState } from 'react'
import styles from "../sidebarBox.module.css"
import { Button } from 'react-bootstrap'
import { AuthContext } from '../../../../context/authContext'
import axios from 'axios'

function Events() {
    const { currentUser } = useContext(AuthContext)

    const [showInput, setShowInput] = useState(false)
    const [expandedImage, setExpandedImage] = useState(false)
    const [calendarImage, setCalendarImage] = useState("")
    const [calendarFromDB, setCalendarFromDB] = useState("")

    const toggleContent = () => setShowInput(!showInput)
    const expandImage = () => setExpandedImage(!expandedImage)
    const calendarImageHandler = event => setCalendarImage(event.target.value)

    const submitHandler = async event => {
        event.preventDefault()

        const newEventCalendar = {
            image_Url: calendarImage
        }

        try {

            const res = await axios.post("/api/post-new-calendar", newEventCalendar)
            setCalendarImage("")
            setShowInput(!setShowInput)

        } catch (error) {
            console.log(error)
        }

        setShowInput(!showInput)
    }

    useEffect(() => {
        const fetchCalendar = async () => {
            try {
                const res = await axios.get("/api/get-calendar/")
                setCalendarFromDB(res?.data)
                console.log(calendarFromDB?.image_Url)
            } catch (error) {
                console.log(error)
            }
        }

        fetchCalendar()
    }, [])


    return (
        <div id={styles.events_box}>
            <h3 className={styles.sidebar_box_title}>CALENDAR</h3>
            <div className={styles.imageContainer}>
                <img
                    onClick={expandImage}
                    className={styles.smallImage}
                    src={calendarFromDB?.image_Url} />

                {expandedImage &&
                    <div className={styles.overlay} onClick={expandImage}>
                        <img
                            className={styles.fullscreenImage}
                            src={calendarFromDB?.image_Url}
                        />
                    </div>
                }
            </div>

            {currentUser?.role === "admin" &&
                <Button onClick={toggleContent} className={styles.calendar_button}>Change Calendar</Button>
            }

            {showInput &&
                <div>
                    <form onSubmit={submitHandler} className={styles.calendar_form}>
                        <input type='url' placeholder='Calendar URL' id={styles.calendar_input} onChange={calendarImageHandler} />
                        <Button type='submit' className={styles.calendar_button}>Change</Button>
                    </form>
                </div>
            }
        </div>
    )
}

export default Events