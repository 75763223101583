import React from 'react'
import ImageCarousel from '../../UI/container/newsrow/image-carousel/ImageCarousel'

import styles from "./support.module.css"


function GalleryPage() {
  return (
    <div className={styles.content_column}>
      <ImageCarousel />

      <h2 className={styles.title}>Support Policies</h2>
      <div className='button_info'>

        <div className={styles.disclaimer}>
        <p className={styles.overlock_Font2}>
        <b>The information below outlines policies that we have when dealing with various support-related issues you may have while playing Lucent Heart Rebirth,
          as well as how we take action towards violations of the Lucent Heart Rebirth <a href="/rules">Rules of Conduct</a>.</b>
          </p>

        <h4><span>Punishment & Suspension Policy</span></h4>
        <p className={styles.overlock_Font2}>
        Depending on the nature and severity of an offense, Lucent Heart Rebirth staff reserves the right to perform the following punishment measures, in any combination or number:
        </p>
        <ol type="1">
        <li className={styles.overlock_Font}>Muting – Your chat privileges will be revoked for any duration.</li>
        <li className={styles.overlock_Font}>Kicking – Your connection to the Lucent Heart Rebirth server will be terminated.</li>
        <li className={styles.overlock_Font}>Temporary Suspension – Your account may be suspended (banned or jailed) for days or months by the Lucent Heart Rebirth team for your offenses.
        Generally speaking, we follow a “three strikes” policy for most offenses, with ban terms increasing in length the more you receive.</li>
        <li className={styles.overlock_Font}>Permanent Suspension – Your account may be suspended permanently and with no appeal either for severe offenses or repeat offenses (i.e. after three strikes for a particular offense).</li>
        </ol>

        <h4><span>Account Sharing</span></h4>
        <p className={styles.overlock_Font2}>In any incident where the Lucent Heart Rebirth staff has determined that an account has been compromised through fault or willful negligence of the end user,
        the Lucent Heart Rebirth staff is not obliged to offer any support to that account.
        This includes, but is not limited to:</p>
        <ol type="1">
        <li className={styles.overlock_Font}>Sharing local game sessions</li>
        <li className={styles.overlock_Font}>Sharing accounts to other friends, soulmates, relatives or any other players</li>
        <li className={styles.overlock_Font}>Buying and/or selling accounts</li>
        <li className={styles.overlock_Font}>Giving away sensitive account details (login, e-mail, password, etc.)</li>
        </ol>
        <p className={styles.overlock_Font2}>Any account for which there is evidence of account sharing or account buying/selling may be suspended at any time,
        especially in situations where that account has been involved in other Lucent Heart Rebirth <a href="/rules">Rules of Conduct</a>.
          Lucent Heart Rebirth is not liable in part or in full for any damages or harm caused, whether real or imagined, through account sharing.</p>

        <h4><span>Lost & Missing Items</span></h4>
        <p className={styles.overlock_Font2}>Users are eligible to ask the Lucent Heart Rebirth staff for item returns in the case of accidental express delivery, selling or deletion.
        Lucent Heart Rebirth makes no guarantee of these items being returned and we are not obligated to fulfill these requests.
        Repeated requests to have items returned may be denied if the number of requests is deemed excessive or unreasonable.</p>
        <p className={styles.overlock_Font2}>Items which are given away or traded to other players, or fail in enchanting/upgrading are not eligible for return under any circumstances unless proven to be result of a glitch, a bug, or an account hack.</p>
        <p className={styles.overlock_Font2}>Attempting to deceive the Lucent Heart Rebirth staff by providing false reports or misinformation with respect to lost or missing items will cause future item return requests to be denied outright,
        whether legitimate or not.
        Increasing falsification of lost items could also result in both temporary and eventually permanent termination of the respective account.</p>

        <h4><span>Item Trading</span></h4>
        <p className={styles.overlock_Font2}>All trades should be performed through the Lucent Heart Rebirth game client.
        Lucent Heart contains a trade interface which provides two-way confirmation from both players involved in the transaction.
        As such, in any situation where a trade might be reversed, only the items involved in a single trade operation can be considered. All trades are performed at-risk to all involved players.
        Once a trade is performed, possession and ownership of the involved items immediately passes to the respective new accounts and any claim to ownership of those items is void.</p>
        <p className={styles.overlock_Font2}>Lucent Heart Rebirth does not support any transactions that occur outside of the Lucent Heart game client’s trade, Cash Shop or personal buying and selling shop interfaces.
        Such transactions may violate the Lucent Heart Rebirth <a href="/rules">Rules of Conduct</a>, and accounts involved in such transactions may be suspended or terminated.</p>

        <h4><span>Security Tips</span></h4>
        <p className={styles.overlock_Font2}>This list of tips below will help you keep your account secure and safe.
        Negligence or ignorance of the basic security tips below may cause the Lucent Heart Rebirth staff to deny your request for assistance.</p>
        <ol type="1">
        <li className={styles.overlock_Font}>Never give anyone else your password for any reason.</li>
        <li className={styles.overlock_Font}>Change your password regularly, and use a strong random password (i.e. "mDFHg14fp3").</li>
        <li className={styles.overlock_Font}>Never use the same password for multiple web sites, games or services.</li>
        <li className={styles.overlock_Font}>Set a Lucent Heart secondary password to protect your inventory.</li>
        <li className={styles.overlock_Font}>Set a different login ID and password from the ones you used at SubaGames and/or at the A1975 server.</li>
        <li className={styles.overlock_Font}>If you do donate or contact our staff team for support, keep a copy of any receipts or e-mails you receive for transactions and communications.</li>
        <li className={styles.overlock_Font}>If you send private messages on discord to us containing sensitive account information of any kind, delete those messages once your issue has been resolved.</li>
        <li className={styles.overlock_Font}>Uncheck the option at the login screen to remember your login ID if you play from a public computer.</li>
        <li className={styles.overlock_Font}>Avoid playing from a public computer or connection (school, library, cyber cafe, etc.) if possible.</li>
        <li className={styles.overlock_Font}>Never leave your computer unattended and unlocked, especially if you live with siblings or children.</li>
        <li className={styles.overlock_Font}>Regularly scan your computer for viruses and other malware using a reputable third-party security suite.</li>
        </ol>
        
        </div>

      </div>

    </div>
  )
}

export default GalleryPage